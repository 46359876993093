import { SUPABASE_STORAGE } from '~/utils/env'

export function getInitials(name: string) {
  let initials = name
    .replace(
      /(?:^|\s|-)+([^\s-])[^\s-]*(?:(?:\s+)(?:the\s+)?(?:jr|sr|ii|2nd|iii|3rd|iv|4th)\.?$)?/gi,
      '$1',
    )
    .toUpperCase()

  if (initials.length > 2) {
    initials = initials.charAt(0) + initials.charAt(initials.length - 1)
  }

  return initials
}

export function searchForInitial(name: string, query: string) {
  return getInitials(name)?.toLocaleLowerCase() === query?.toLocaleLowerCase()
}

type StorageOpts = {
  resize?: 'cover' | 'fill' | 'contain'
  width?: number
  height?: number
}

export function getAvatar(email?: string, opts: StorageOpts = {}) {
  if (!email) return ''

  opts.height = opts.height || 100
  opts.width = opts.width || 100

  const params = new URLSearchParams(
    opts as string | string[][] | Record<string, string> | URLSearchParams | undefined,
  ).toString()

  return `${SUPABASE_STORAGE}/wra_headshots/${email.trim()}.jpg?${params}`.toLocaleLowerCase()
}
