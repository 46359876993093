export enum ErastusRoles {
  Admin = 'Erastus.Admin',
  HRAdmins = 'Erastus.HRAdmins',
  NewProjectCreator = 'Erastus.NewProjectCreator',
  ProjectEditor = 'Erastus.ProjectEditor',
  StaffingAdmins = 'Erastus.StaffingAdmins',
  TimeApprover = 'Erastus.TimeApprover',
  TransferPortal = 'Erastus.TransferPortal',
  WRAiAdmin = 'Erastus.WRAiAdmin',
  WRAiUser = 'Erastus.WRAiUser',
  Developer = 'Erastus.Developer',
}
